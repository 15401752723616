import React from 'react';
import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';
import useRoute from '@vl/hooks/useGbRouteDe';
import i18n from 'i18n-js';
import moment from 'moment';
import MemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import gstyles from '@vl/gstyles';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import { flattenGet } from '@vl/mod-utils/flattenGet';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import _ from 'lodash';
import { v4 as getUUid } from 'uuid';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            stepForm_0: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const user_id = ctx.apply('currentUserModel.getUserId');
              const form = useFormik({
                initialValues: {
                  user_id,
                  template_id: ctx.get('@item.id'),
                  name: ctx.get('@item.name'),
                  photo_url: !ctx.get('@item.photo_url')
                    ? ''
                    : [
                        {
                          url: ctx.get('@item.photo_url'),
                          status: 'success',
                        },
                      ],
                  description: ctx.get('@item.description'),
                  link_urls: _.map(ctx.get('@item.link_urls'), (item, index) => {
                    return {
                      id: index,
                      content: item,
                    };
                  }),
                  materials: _.map(ctx.get('@item.materials'), AssetModelFormatter.urlToFile()),
                },
                onSubmit: async (values, actions) => {
                  try {
                    ctx.apply('formStepsModel.next');
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({
                  name: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  description: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                }),
              });

              _.assign(form, {
                canSubmit: form.isValid,
                array: {
                  add: (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const valueToInsert = _.clone(_.last(fieldArray));
                    const cleanItem = (val) => {
                      let rtn = '';
                      if (_.isPlainObject(val)) {
                        rtn = _.clone(val);
                        for (const key in valueToInsert) {
                          rtn[key] = cleanItem(valueToInsert[key]);
                        }
                      } else if (_.isArray(val)) {
                        rtn = [];
                      }
                      return rtn;
                    };

                    let newItemVal = cleanItem(valueToInsert);
                    if (_.isEmpty(valueToInsert)) {
                      newItemVal = {
                        name: '',
                        id: getUUid(),
                        photo_url: [],
                        description: '',
                        link_urls: [
                          {
                            content: '',
                          },
                        ],
                        materials: [],
                      };
                    } else {
                      newItemVal.id = valueToInsert.id + 1;
                    }
                    form.setFieldValue(field, [...fieldArray, newItemVal]);
                  },
                  addMultiple: async (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const quantity = await _.parseInt(_.get(form, 'values.multiple_quantity', 0));

                    const array = [...fieldArray];
                    const newArr = [];
                    _.range(quantity).map((item) => {
                      return newArr.push({
                        name: '',
                        id: getUUid() + item,
                        photo_url: [],
                        description: '',
                        link_urls: [
                          {
                            content: '',
                          },
                        ],
                        materials: [],
                      });
                    });
                    const data = _.concat(array, newArr);

                    form.setFieldValue(field, data);
                  },

                  remove: (field, index) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.cloneDeep(_.castArray(fieldValue));
                    fieldArray.splice(index, 1);
                    const newValue = [...fieldArray];
                    form.setFieldValue(field, newValue);
                  },
                },
                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
              });

              return form;
            }),

            stepForm_1: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');

              const form = useFormik({
                initialValues: {
                  multiple_quantity: 0,
                  session_duration: 30 * 60,
                  start_at: moment().startOf('day'),
                  type: 'online',
                  sessions: [{ repeat_pattern: [], start_at: '' }],
                  add_lesson_time_manually: false,
                  lessons: _.map(ctx.get('@item.course_template_lessons'), (item) => {
                    return {
                      id: _.get(item, 'id', getUUid()),
                      name: _.get(item, 'name', ''),
                      description: _.get(item, 'description', ''),
                      photo_url: !_.get(item, 'photo_url', '')
                        ? ''
                        : [
                            {
                              url: _.get(item, 'photo_url', ''),
                              status: 'success',
                            },
                          ],
                      start_at: moment().startOf('day'),
                      link_urls: _.map(_.get(item, 'link_urls'), (item, index) => {
                        return {
                          id: index,
                          content: item,
                        };
                      }),
                      teachers: [],
                      materials: _.map(_.get(item, 'materials'), AssetModelFormatter.urlToFile()),
                    };
                  }),
                  teachers: [],
                },
                onSubmit: async (values, actions) => {
                  try {
                    ctx.apply('formStepsModel.next');
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({
                  session_duration: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  start_at: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  lessons: Yup.array().of(Yup.object().shape({
                    start_at: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  })),
                }),
              });

              const calculateLessonTime = (number_of_lesson, start_date) => {
                const end_date = moment(start_date)
                  .add(number_of_lesson * 7, 'days')
                  .format('YYYY-MM-DD');
                const sessions = _.get(form, 'values.sessions', []);

                const getDates = (startDate, endDate) => {
                  let current = moment(startDate);
                  const end = moment(endDate);
                  const results = [];

                  while (current.isSameOrBefore(end)) {
                    const date = moment(current.clone())
                      .startOf('day')
                      .format('YYYY-MM-DD');

                    _.map(sessions, (day) => {
                      const session_start_at = _.get(day, 'start_at', '');
                      if (!session_start_at) {
                        return;
                      }
                      const start_at = `${date}${moment(session_start_at).format('THH:mm:ss.SSSSZ')}`;
                      if (_.includes(_.get(day, 'repeat_pattern'), current.clone().days())) {
                        results.push({
                          start_at,
                        });
                      }
                    });

                    current.add(1, 'day');
                  }

                  return _.take(results, number_of_lesson);
                };

                return getDates(start_date, end_date);
              };

              React.useEffect(() => {
                const lessons = _.get(form, 'values.lessons', []);
                const number_of_lesson = ctx.get('@item.course_template_lessons.length');
                const start_date = _.get(form, 'values.start_at', '');
                const lessonTimes = calculateLessonTime(number_of_lesson, start_date);
                const newLessons = _.map(lessons, (lesson, index) => {
                  return {
                    ...lesson,
                    start_at: _.get(lessonTimes, `[${index}].start_at`),
                  };
                });

                form.setFieldValue('lessons', newLessons);
              }, [_.get(form, 'values.start_at'), _.get(form, 'values.sessions')]);

              _.assign(form, {
                canSubmit: form.isValid,

                handleChangeCheckbox: (value) => {
                  const { checked, name } = value.event.target;

                  const field = _.get(value, 'field', '');
                  const rec = _.get(value, 'value', '');

                  if (checked) {
                    form.setFieldValue(field, [..._.get(form.values, field), { ...rec }]);
                  } else {
                    form.setFieldValue(
                      field,
                      _.filter(_.get(form, `values.${field}`), (v) => {
                        return _.get(v, 'id') !== name;
                      })
                    );
                  }
                },

                array: {
                  add: (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const valueToInsert = _.clone(_.last(fieldArray));
                    const cleanItem = (val) => {
                      let rtn = '';
                      if (_.isPlainObject(val)) {
                        rtn = _.clone(val);
                        for (const key in valueToInsert) {
                          rtn[key] = cleanItem(valueToInsert[key]);
                        }
                      } else if (_.isArray(val)) {
                        rtn = [];
                      }
                      return rtn;
                    };

                    let newItemVal = cleanItem(valueToInsert);
                    if (_.isEmpty(valueToInsert)) {
                      newItemVal = {
                        id: getUUid(),
                      };
                    } else {
                      newItemVal.id = valueToInsert.id + 1;
                    }
                    form.setFieldValue(field, [...fieldArray, newItemVal]);
                  },
                  addMultiple: async (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const quantity = await _.parseInt(_.get(form, 'values.multiple_quantity', 0));

                    const array = [...fieldArray];
                    const newArr = [];
                    _.range(quantity).map((item) => {
                      return newArr.push({
                        name: '',
                        id: getUUid(),
                        photo_url: [],
                        description: '',
                        start_at: moment().startOf('day'),
                        link_urls: [
                          {
                            content: '',
                          },
                        ],
                        teachers: [],
                        materials: [],
                      });
                    });
                    const data = _.concat(array, newArr);

                    form.setFieldValue(field, data);
                  },

                  remove: (field, index) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.cloneDeep(_.castArray(fieldValue));
                    fieldArray.splice(index, 1);
                    const newValue = [...fieldArray];
                    form.setFieldValue(field, newValue);
                  },
                },
                selectOptions: {
                  session_duration: _.range(15, 240, 15).map((count) => ({
                    value: count * 60,
                    label: `${count} ${ctx.apply('i18n.t', 'ZoomToolCourse.Form.minutes')}`,
                  })),
                  course_type: [
                    {
                      value: 'online',
                      label: ctx.apply('i18n.t', 'ZoomToolCourse.Form.online'),
                    },
                    {
                      value: 'offline',
                      label: ctx.apply('i18n.t', 'ZoomToolCourse.Form.offline'),
                    },
                  ],
                  repeat_pattern: _.sortBy(
                    [
                      ..._.range(0, 7, 1).map((count) => ({
                        value: count,
                        label: `${moment(count, 'e')
                          .startOf('week')
                          .isoWeekday(count)
                          .locale(i18n.locale)
                          .format('ddd')}`,
                      })),
                    ],
                    function(o) {
                      return o.value || Infinity;
                    }
                  ),
                },

                handleSelectAllSessions: (name, value) => {
                  if (value) {
                    form.setFieldValue(name, _.range(0, 7, 1));
                  } else {
                    form.setFieldValue(name, []);
                  }
                },
                handleSelectSessions: (name, value, index) => {
                  const currentValue = _.get(form, `values.${name}`, []);
                  let newValue = [];
                  if (_.isArray(value)) {
                    newValue = _.xor(currentValue, value);
                  } else {
                    newValue = _.xor(currentValue, [value]);
                  }
                  form.setFieldValue(name, newValue);
                  if (_.isEqual(newValue.sort(), _.range(0, 7, 1).sort())) {
                    form.setFieldValue(`sessions[${index}].select`, true);
                  }
                  if (_.isEmpty(newValue)) {
                    form.setFieldValue(`sessions[${index}].select`, false);
                  }
                },

                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
              });
              return form;
            }),

            stepForm_2: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const account_id = ctx.apply('accountModel.getAccountId');
              const route = useRoute();
              const user_id = ctx.apply('currentUserModel.getUserId');
              const form = useFormik({
                initialValues: {
                  students: [],
                },
                onSubmit: async (values, actions) => {
                  try {
                    const formSteps = ctx.get('formSteps');
                    const course_payload = {
                      account_id,
                      user_id,
                      template_id: _.get(formSteps[0], 'form.values.template_id', ''),
                      name: _.get(formSteps[0], 'form.values.name', ''),
                      description: _.get(formSteps[0], 'form.values.description', ''),
                      photo_url: _.get(formSteps[0], 'form.values.photo_url.0.url'),
                      materials: _.castArray(_.get(formSteps[0], 'form.values.materials', [])),
                      link_urls: `{${_.map(
                        _.castArray(_.get(formSteps[0], 'form.values.link_urls', [])),
                        'content'
                      ).join(',')}}`,
                      start_at: _.get(formSteps[1], 'form.values.start_at', ''),
                      type: _.get(formSteps[1], 'form.values.type', ''),
                      session_duration: _.get(formSteps[1], 'form.values.session_duration', ''),
                    };

                    const client = await getClient();
                    const course_res = await client.request(
                      gql`
                        mutation CreateCourse($object: b2b_course_insert_input = {}) {
                          insert_b2b_course_one(
                            object: $object
                            on_conflict: { constraint: course_pkey, update_columns: id }
                          ) {
                            id
                          }
                        }
                      `,
                      { object: course_payload }
                    );

                    const course_id = _.get(course_res, 'insert_b2b_course_one.id', '');

                    const teacher_payload = _.map(_.get(formSteps[1], 'form.values.teachers', ''), (teacher) => {
                      return {
                        user_id: _.get(teacher, 'user_id', ''),
                        account_id: account_id,
                        course_id: course_id,
                        role: _.get(teacher, 'role', ''),
                      };
                    });

                    const student_payload = _.map(_.get(formSteps[2], 'form.values.students', ''), (student) => {
                      return {
                        user_id: _.get(student, 'user_id', ''),
                        account_id,
                        course_id,
                      };
                    });

                    const room_payload = _.map(_.get(formSteps[1], 'form.values.lessons', []), (lesson) => {
                      const start_at = _.get(lesson, 'start_at', '');
                      const duration = _.get(formSteps[1], 'form.values.session_duration', '');
                      return {
                        account_id,
                        course_id,
                        start_at,
                        end_at: moment(start_at)
                          .clone()
                          .add(duration, 'second')
                          .format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
                        name: _.get(lesson, 'name', ''),
                        description: _.get(lesson, 'description', ''),
                        photo_url: _.get(lesson, 'photo_url.0.url'),
                        materials: _.castArray(_.get(lesson, 'materials', [])),
                        link_urls: `{${_.map(_.castArray(_.get(lesson, 'link_urls', [])), 'content').join(',')}}`,
                        duration,
                      };
                    });

                    if (course_id) {
                      const room_member_res = await client.request(
                        gql`
                          mutation CreateCourseTeacherAndRoom(
                            $rooms: [b2b_course_room_insert_input!] = {}
                            $teachers: [b2b_course_teacher_insert_input!] = {}
                            $students: [b2b_course_enroll_insert_input!] = {}
                          ) {
                            rooms: insert_b2b_course_room(
                              objects: $rooms
                              on_conflict: { constraint: course_room_pkey, update_columns: course_id }
                            ) {
                              affected_rows
                              returning {
                                room_id: id
                              }
                            }
                            teachers: insert_b2b_course_teacher(objects: $teachers) {
                              affected_rows
                              returning {
                                id
                              }
                            }
                            students: insert_b2b_course_enroll(objects: $students) {
                              affected_rows
                              returning {
                                id
                              }
                            }
                          }
                        `,
                        { teachers: teacher_payload, rooms: room_payload, students: student_payload }
                      );

                      if (room_member_res) {
                        const clone = [..._.get(formSteps[1], 'form.values.lessons', [])];
                        const room_ids = _.get(room_member_res, 'rooms.returning', []);

                        const mapping_id_rooms = _.map(clone, (lesson, index) => {
                          return {
                            ...lesson,
                            teachers: _.map(_.get(lesson, 'teachers', []), (teacher) => {
                              return {
                                ...teacher,
                                room_id: room_ids[index].room_id,
                              };
                            }),
                          };
                        });

                        function cartesianProductOf() {
                          return _.reduce(
                            arguments,
                            function(a, b) {
                              return _.flatten(
                                _.map(a, function(x) {
                                  return _.map(b, function(y) {
                                    return x.concat([y]);
                                  });
                                }),
                                true
                              );
                            },
                            [[]]
                          );
                        }

                        const room_teachers = _.map(mapping_id_rooms, (item) => {
                          return { teachers: flattenGet(item, 'teachers') };
                        });

                        const teacher_flatten = flattenGet(room_teachers, 'teachers');

                        const room_teacher_payload = _.map(teacher_flatten, (room_teacher) => {
                          return {
                            user_id: _.get(room_teacher, 'user_id', ''),
                            course_room_id: _.get(room_teacher, 'room_id', ''),
                            account_id,
                            course_id,
                            teacher_type: _.get(room_teacher, 'role', ''),
                          };
                        });

                        const student_clone = [..._.get(formSteps[2], 'form.values.students', '')];
                        const cross_join_arr = cartesianProductOf(student_clone, room_ids);

                        const attendee_payload = _.map(cross_join_arr, (item) => {
                          return {
                            account_id,
                            course_id,
                            user_id: _.get(item, '[0].user_id', ''),
                            course_room_id: _.get(item, '[1].room_id', ''),
                          };
                        });

                        const room_teacher_res = await client.request(
                          gql`
                            mutation CreateCourseRoomTeacher(
                              $teachers: [b2b_course_room_teacher_insert_input!] = {}
                              $attendees: [b2b_course_room_attendee_insert_input!] = {}
                            ) {
                              teachers: insert_b2b_course_room_teacher(objects: $teachers) {
                                affected_rows
                                returning {
                                  id
                                }
                              }
                              attendees: insert_b2b_course_room_attendee(objects: $attendees) {
                                affected_rows
                                returning {
                                  id
                                }
                              }
                            }
                          `,
                          { teachers: room_teacher_payload, attendees: attendee_payload }
                        );

                        if (room_teacher_res) {
                          route.navigate(ctx.apply('routeStore.toUrl', 'toolAccountCourseCreated', { id: course_id }));
                        }
                      }
                    }
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                canSubmit: form.isValid,
                handleChangeCheckbox: (value) => {
                  const { checked, name } = value.event.target;

                  const field = _.get(value, 'field', '');
                  const rec = _.get(value, 'value', '');

                  if (checked) {
                    form.setFieldValue(field, [..._.get(form.values, field), { ...rec }]);
                  } else {
                    form.setFieldValue(
                      field,
                      _.filter(_.get(form, `values.${field}`), (v) => {
                        return _.get(v, 'id') !== name;
                      })
                    );
                  }
                },
                array: {
                  remove: (field, index) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.cloneDeep(_.castArray(fieldValue));
                    fieldArray.splice(index, 1);
                    const newValue = [...fieldArray];
                    form.setFieldValue(field, newValue);
                  },
                },
              });
              return form;
            }),

            allColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return <div>{index + 1}</div>;
                  },
                  width: 50,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.teacher')}
                    </div>
                  ),
                  render: (val) => {
                    return (
                      <div className="identify-container">
                        <div className="table-cell align-top">
                          <Avatar size={40} src={MemberFormatter.avatarUrl(ctx)(val)} />
                        </div>

                        <div style={{ verticalAlign: 'middle' }} className="table-cell align-middle pl-5">
                          <div className="flex flex-col">
                            <div className="text-xs">{MemberFormatter.fullName(ctx)(val)}</div>
                            <div className="text-xs"> Type: {_.get(val, 'role', '')}</div>
                          </div>
                        </div>
                      </div>
                    );
                  },
                  require: true,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.email')}
                    </div>
                  ),
                  render: (val) => {
                    return <div className="text-xs">{MemberFormatter.email(ctx)(val)}</div>;
                  },
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.actions')}
                    </div>
                  ),
                  width: 300,
                  render: (text, record, index) => {
                    return (
                      <div className="text-center">
                        <div
                          className="inline-block cursor-pointer"
                          onClick={() => ctx.apply('stepForm_1.array.remove', 'teachers', index)}
                        >
                          {gstyles.icons({
                            name: 'remove-circle',
                            fill: gstyles.colors.red500,
                            size: 24,
                          })}
                        </div>
                      </div>
                    );
                  },
                },
              ];
              return columns;
            }),

            tableName: hook(() => 'tblCourseTeacher'),

            tableColumn: hook((ctx) => {
              const data = ctx.get('stepForm_1.values.teachers');
              const tableName = ctx.get('tableName', 'tblCourseTeacher');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allColumns');

              const columns = _.filter(ctx.get('allColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                dataSource: data,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),

            allStudentColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return <div>{index + 1}</div>;
                  },
                  width: 50,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.student')}
                    </div>
                  ),
                  render: (val) => {
                    return (
                      <div className="identify-container">
                        <div className="table-cell align-top">
                          <Avatar size={40} src={MemberFormatter.avatarUrl(ctx)(val)} />
                        </div>

                        <div style={{ verticalAlign: 'middle' }} className="table-cell align-middle pl-5">
                          <div className="flex flex-col">
                            <div className="text-xs">{MemberFormatter.fullName(ctx)(val)}</div>
                            <div className="text-xs"> Type: {_.get(val, 'role', '')}</div>
                          </div>
                        </div>
                      </div>
                    );
                  },
                  require: true,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.email')}
                    </div>
                  ),
                  render: (val) => {
                    return <div className="text-xs">{MemberFormatter.email(ctx)(val)}</div>;
                  },
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.actions')}
                    </div>
                  ),
                  width: 300,
                  render: (text, record, index) => {
                    return (
                      <div className="text-center">
                        <div
                          className="inline-block cursor-pointer"
                          onClick={() => ctx.apply('stepForm_2.array.remove', 'students', index)}
                        >
                          {gstyles.icons({
                            name: 'remove-circle',
                            fill: gstyles.colors.red500,
                            size: 24,
                          })}
                        </div>
                      </div>
                    );
                  },
                },
              ];
              return columns;
            }),

            tableStudentName: hook(() => 'tblCourseStudent'),

            tableStudentColumn: hook((ctx) => {
              const data = ctx.get('stepForm_2.values.students');
              const tableName = ctx.get('tableName', 'tblCourseStudent');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allStudentColumns');

              const columns = _.filter(ctx.get('allStudentColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                dataSource: data,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),
            formSteps: hook((ctx) => {
              return [
                {
                  form: ctx.get('stepForm_0'),
                },
                {
                  form: ctx.get('stepForm_1'),
                },
                {
                  form: ctx.get('stepForm_2'),
                },
              ];
            }),

            formStepsModel: hook((ctx) => {
              const [currentStep, $currentStep] = React.useState(0);
              const formSteps = ctx.get('formSteps');
              const stepCount = formSteps.length;
              const ref = React.useRef({});
              _.assign(ref.current, { currentStep, $currentStep });
              const model = {
                current: ref.current.currentStep,
                isStep: (index) => index === ref.current.currentStep,
                next: () => {
                  ref.current.$currentStep(Math.min(ref.current.currentStep + 1, stepCount - 1));
                },
                prev: () => {
                  ref.current.$currentStep(Math.max(ref.current.currentStep - 1, 0));
                },
                isLast: () => {
                  return ref.current.currentStep === stepCount - 1;
                },
                isFirst: () => {
                  return ref.current.currentStep === 0;
                },
                hasNext: () => {
                  return stepCount > 1 && !model.isLast();
                },
                hasPrev: () => {
                  return stepCount > 1 && !model.isFirst();
                },
                getForm: () => {
                  return ctx.get(`formSteps.${ref.current.currentStep}.form`);
                },
              };
              return model;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
