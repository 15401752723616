import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Col, Row, Upload, Progress, Modal } from 'antd';
import Button from '@uz/unitz-components-web/Button';
import InputQuillEditor from '@uz/unitz-components-web/InputQuillEditor';
import { FormItem, InputNumber, DatePicker } from 'formik-antd';
import InputText from '@uz/unitz-components-web/InputText';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import styled from 'styled-components';
import { Image } from '@uz/unitz-components-web/Image';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cx from 'classnames';
import moment from 'moment';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import i18n from 'i18n-js';
import { START_DATE_YEAR_ML_FORMAT } from '@vl/mod-utils/datetime';
import { datePickerLocaleMapping } from '@vl/mod-utils/datePickerLocaleMapping';
import AddAssistantModal from './AddAssistantModal';
import AddTeacherModal from './AddTeacherModal';
import TeacherTable from './TeacherTable';

const CoverPhotoInput = styled(Upload.Dragger)`
  .ant-upload.ant-upload-btn {
    padding: 0px;
  }
`;

const AttachmentInputContainer = styled(CustomInput)`
  .ant-upload-list-item {
    .ant-upload-list-item-progress {
      padding-right: 32px;
    }
  }
`;
const AttachmentInput = styled(Upload.Dragger)`
  .ant-upload.ant-upload-btn {
    padding: 0px;
  }
`;

const CustomEditor = styled.div`
  .text-editor {
    .ql-toolbar {
      border-radius: 8px 8px 0 0;
      border-color: ${gstyles.colors.border};
    }
    .quill {
      .ql-container {
        border-radius: 0 0 8px 8px;
        border-color: ${gstyles.colors.border};
        .ql-editor {
          &::before {
            color: ${gstyles.colors.placeHolder};
          }
        }
      }
    }
  }
`;

const gutter = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
};

const ScrollX = styled.div`
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${gstyles.colors.background2};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${gstyles.colors.placeHolder};
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${gstyles.colors.black200};
  }
`;

const SESSION_START_TIME_FORMAT = 'HH:mm';

const View8 = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [isTeacherVisible, $isTeacherVisible] = React.useState(false);
  const [isAssistantVisible, $isAssistantVisible] = React.useState(false);

  const locale = i18n.locale;

  const toggle = () => {
    setIsVisible(!isVisible);
  };

  const openModal = () => {
    $isTeacherVisible(true);
  };

  const hideModal = () => {
    $isTeacherVisible(false);
  };

  const openModalAssistant = () => {
    $isAssistantVisible(true);
  };

  const hideModalAssistant = () => {
    $isAssistantVisible(false);
  };

  return (
    <DIV className="component">
      <DIV forceCtx>
        <Row gutter={gutter}>
          <Col span={6}>
            <div className="bg-background1 px-2 py-3 rounded-lg">
              <ScrollX
                style={{
                  overflowY: ctx.get('dndModel.itemList.length') > 10 ? 'scroll' : 'auto',
                  height: ctx.get('dndModel.itemList.length') > 10 ? 650 : 'auto',
                }}
                className="list-container"
              >
                {_.map(ctx.get('dndModel.itemList'), (item, index) => (
                  <div className="item-container cursor-pointer">
                    <div onClick={() => ctx.apply('dndModel.setCurrent', item.id)}>
                      <div
                        className={cx('px-2 py-4 mb-2 flex items-center justify-between rounded-lg', {
                          'bg-transparent': ctx.get('dndModel.current') !== item.id,
                          'bg-brandb500': ctx.get('dndModel.current') === item.id,
                        })}
                      >
                        <div className="flex items-center space-x-2">
                          <div
                            className={cx('text-sm', {
                              'text-main': ctx.get('dndModel.current') !== item.id,
                              'text-white500': ctx.get('dndModel.current') === item.id,
                            })}
                          >
                            {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.lesson')} {index + 1}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </ScrollX>
            </div>
          </Col>
          <Col span={18}>
            {_.map(ctx.get('form.values.lessons'), (item, index) => {
              if (ctx.get('dndModel.current') === item.id) {
                const dateFormatter = (value) => {
                  const TIME_FORMAT = 'HH:mm';
                  // const DATE_FORMAT = 'YYYY/MM/DD';
                  const session_duration = ctx.get('form.values.session_duration') || 60 * 60;
                  const endValue = value.clone().add(session_duration, 'second');

                  return `${value.format(
                    `${_.get(START_DATE_YEAR_ML_FORMAT, locale, 'de')} ${TIME_FORMAT}`
                  )} - ${endValue.format(TIME_FORMAT)}`;
                };
                return (
                  <div className="steps-content" key={index}>
                    <DIV>
                      {ctx.debug(() => {
                        ctx.set('lesson_index', index);
                      })}

                      <CustomInput className="mb-3">
                        <FormItem
                          label={
                            <span className="text-sm font-semibold text-sub">
                              {ctx.apply('i18n.t', 'ZoomToolCourse.Form.lesson_time')}
                            </span>
                          }
                          name={`lessons[${index}].start_at`}
                        >
                          <DatePicker
                            allowClear={false}
                            size="middle"
                            showTime={{
                              hourStep: 1,
                              minuteStep: 15,
                              format: SESSION_START_TIME_FORMAT,
                            }}
                            suffixIcon={gstyles.icons({
                              name: 'calendar',
                              size: 20,
                              fill: gstyles.colors.sub,
                            })}
                            format={dateFormatter}
                            locale={datePickerLocaleMapping(locale)}
                            name={`lessons[${index}].start_at`}
                            className="w-full"
                            disabledDate={(current) => {
                              return current && current < moment().valueOf();
                            }}
                          />
                        </FormItem>
                      </CustomInput>
                      <FormItem
                        name={`lessons[${index}].name`}
                        label={
                          <span className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.lesson_title')}
                          </span>
                        }
                      >
                        <CustomInput>
                          <InputText name={`lessons[${index}].name`} size="medium" />
                        </CustomInput>
                      </FormItem>
                      {/* <FormItem
                        name={`lessons[${index}].photo_url`}
                        label={
                          <span className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cover')}
                          </span>
                        }
                      >
                        <CustomInput>
                          <div className="relative">
                            <CoverPhotoInput
                              {...ctx.apply('form.getUploaderProps', {
                                fieldName: `lessons[${index}].photo_url`,
                                multiple: false,
                              })}
                              itemRender={() => null}
                            >
                              {!ctx.get(`form.values.lessons[${index}].photo_url.0`) ? (
                                <div className="flex flex-col items-center justify-center py-10">
                                  <div className="text-center ant-upload-text">
                                    {gstyles.icons({ name: 'photo-library', size: 24, fill: gstyles.colors.sub })}
                                  </div>
                                  <div className="text-sm my-2 font-normal ant-upload-hint text-main">
                                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cover_placeholder')}
                                  </div>
                                </div>
                              ) : (
                                <div className="flex items-center justify-center">
                                  <Image
                                    alt="photo"
                                    src={ctx.get(`form.values.lessons[${index}].photo_url.0.url`)}
                                    className="overflow-hidden rounded-lg"
                                    width={364}
                                    height={205}
                                  />
                                </div>
                              )}
                            </CoverPhotoInput>
                            {ctx.get(`form.values.lessons[${index}].photo_url.0`) && (
                              <div className="absolute top-0 right-0">
                                <Button
                                  type="link"
                                  name="ink"
                                  onClick={() => ctx.apply('form.setFieldValue', `lessons[${index}].photo_url`, [])}
                                >
                                  <span>{gstyles.icons({ name: 'delete', size: 16, fill: gstyles.colors.sub })}</span>
                                </Button>
                              </div>
                            )}
                            {ctx.get(`form.values.lessons[${index}].photo_url.0.status`) === 'uploading' && (
                              <div className="absolute inset-0 flex items-center justify-center p-4 bg-black opacity-50">
                                <Progress
                                  width={80}
                                  type="circle"
                                  percent={ctx.get(`form.values.lessons[${index}].photo_url.0.percent`)}
                                />
                              </div>
                            )}
                          </div>
                          <div className="flex items-center mt-2">
                            <div>{gstyles.icons({ name: 'info', size: 16, fill: gstyles.colors.sub })}</div>
                            <div style={{ paddingLeft: 4 }} className="text-sub text-xs font-normal">
                              {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cover_hint')}
                            </div>
                          </div>
                        </CustomInput>
                      </FormItem> */}
                      <FormItem
                        name={`lessons[${index}].description`}
                        label={
                          <span className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.description')}
                          </span>
                        }
                      >
                        <CustomEditor>
                          <InputQuillEditor name={`lessons[${index}].description`} toolbarId="lesson-description" />
                        </CustomEditor>
                      </FormItem>
                      <FormItem
                        name={`lessons[${index}].materials`}
                        label={
                          <span className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.material')}
                          </span>
                        }
                      >
                        <div className="flex items-center mb-2">
                          <div>{gstyles.icons({ name: 'info', size: 16, fill: gstyles.colors.sub })}</div>
                          <div style={{ paddingLeft: 4 }} className="text-sub text-xs font-normal">
                            {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.material_hint')}
                          </div>
                        </div>
                        <AttachmentInputContainer>
                          <AttachmentInput
                            {...ctx.apply('form.getUploaderProps', {
                              fieldName: `lessons[${index}].materials`,
                            })}
                          >
                            <div className="flex flex-row items-center justify-center py-10">
                              <Button name="ink">{ctx.apply('i18n.t', 'Course.Info.Action.select_files')}</Button>
                              <div className="flex flex-row items-center justify-center ml-4">
                                <span>
                                  {gstyles.icons({ name: 'cloud-upload', size: 24, fill: gstyles.colors.sub })}
                                </span>
                                <div className="text-base font-normal text-main pl-2">
                                  {ctx.apply('i18n.t', 'Course.Info.Action.drop_files_here')}
                                </div>
                              </div>
                            </div>
                          </AttachmentInput>
                        </AttachmentInputContainer>
                      </FormItem>
                      <div className="mb-6">
                        {_.map(ctx.get(`form.values.lessons[${index}].link_urls`), (lesson, linkIndex) => {
                          return (
                            <Row key={_.get(lesson, 'id', '')} gutter={gutter}>
                              <Col span={22}>
                                <CustomInput>
                                  <FormItem name={`lessons[${index}].link_urls[${linkIndex}].content`}>
                                    <InputText
                                      name={`lessons[${index}].link_urls[${linkIndex}].content`}
                                      size="medium"
                                      placeholder={ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.url_placeholder')}
                                    />
                                  </FormItem>
                                </CustomInput>
                              </Col>

                              <Col span={2}>
                                <div
                                  className="cursor-pointer inline-block"
                                  onClick={() =>
                                    ctx.apply('form.array.remove', `lessons[${index}].link_urls`, linkIndex)
                                  }
                                >
                                  {gstyles.icons({
                                    name: 'remove-circle',
                                    fill: gstyles.colors.red500,
                                    size: 24,
                                  })}
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                        <Row className="my-1" gutter={gutter}>
                          <Col span={8}>
                            <Button
                              type="outline"
                              size="small"
                              className="w-full"
                              onClick={() => ctx.apply('form.array.add', `lessons[${index}].link_urls`)}
                              icon={gstyles.icons({
                                name: 'add-circle',
                                size: 20,
                                fill: gstyles.colors.brandb500,
                                className: 'inline-block mx-2',
                              })}
                            >
                              {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.add_link')}
                            </Button>
                          </Col>
                        </Row>
                        {/* <div className="text-sm text-sub mt-4 mb-2 font-semibold">
                          {ctx.apply('i18n.t', 'ZoomToolCourse.Form.teacher')}{' '}
                          {!!ctx.get(`form.values.lessons[${index}].teachers.length`) &&
                            `(${ctx.get(`form.values.lessons[${index}].teachers.length`)})`}
                        </div>
                        <TeacherTable /> */}
                        {/* <Row className="my-6">
                          <Col span={16}>
                            <Row align="middle" gutter={gutter}>
                              <Col className="mx-auto" span={12}>
                                <Button
                                  type="outline"
                                  className="mx-auto"
                                  size="small"
                                  block
                                  icon={gstyles.icons({
                                    name: 'add-circle',
                                    size: 20,
                                    fill: gstyles.colors.brandb500,
                                    className: 'inline-block mx-2',
                                  })}
                                  onClick={openModal}
                                >
                                  {ctx.apply('i18n.t', 'ZoomToolCourse.Action.add_teacher')}
                                </Button>
                                <Modal
                                  width={1248}
                                  footer={null}
                                  className="rounded-lg overflow-hidden"
                                  maskStyle={{ background: gstyles.colors.black300 }}
                                  visible={isTeacherVisible}
                                  centered
                                  bodyStyle={{ paddingRight: 10, paddingLeft: 10 }}
                                  wrapClassName={'my-modal'}
                                  onCancel={hideModal}
                                >
                                  <AddTeacherModal />
                                </Modal>
                              </Col>
                              <Col className="mx-auto" span={12}>
                                <Button
                                  type="outline"
                                  name="secondary"
                                  className="mx-auto"
                                  size="small"
                                  block
                                  icon={gstyles.icons({
                                    name: 'add-circle',
                                    size: 20,
                                    fill: gstyles.colors.branda500,
                                    className: 'inline-block mx-2',
                                  })}
                                  onClick={openModalAssistant}
                                >
                                  {ctx.apply('i18n.t', 'ZoomToolCourse.Action.add_assistant')}
                                </Button>
                                <Modal
                                  width={1248}
                                  footer={null}
                                  className="rounded-lg overflow-hidden"
                                  maskStyle={{ background: gstyles.colors.black300 }}
                                  visible={isAssistantVisible}
                                  centered
                                  bodyStyle={{ paddingRight: 10, paddingLeft: 10 }}
                                  wrapClassName={'my-modal'}
                                  onCancel={hideModalAssistant}
                                >
                                  <AddAssistantModal />
                                </Modal>
                              </Col>
                            </Row>
                          </Col>
                        </Row> */}
                      </div>
                    </DIV>
                  </div>
                );
              }
            })}
          </Col>
        </Row>
      </DIV>
    </DIV>
  );
};

export default displayName(View8);
